.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.gallery-grid img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  padding: 0.5rem;
  vertical-align: bottom;
  border-radius: 0.9rem;
}

/* mobile */
@media (max-width: 767px) {
  .gallery-grid .gallery-container {
    height: 20vh;
    flex-grow: 1;
  }
}

/* desktop */
@media (min-width: 768px) {
  .gallery-grid .gallery-container {
    height: 40vh;
    flex-grow: 1;
  }
}
