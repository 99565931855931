.hero-img {
  object-fit: cover;
  object-position: 50%;
  width: 100%;
}

/* mobile */
@media (max-width: 767px) {
  .hero-img {
    max-height: 10rem;
  }
  .home .collage {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }
  .about-section {
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 15%;
    padding-right: 15%;
    background-color: var(--color-theme-light);
    margin-bottom: 3%;
    border-radius: 0.3rem;
  }
  .about-section p {
    text-align: center;
  }
  .about-img {
    width: auto;
    max-height: 10rem;
    border-radius: 100%;
    object-fit: fill;
    object-position: 10% 50%;
    margin-bottom: 3%;
  }
  .about-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .category-section {
    padding-top: 1%;
    padding-bottom: 3%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .category-section .column-center {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .category-section .category-wrapper {
    overflow: hidden;
    border-radius: 100%;
    width: 300px;
    height: 300px;
  }
  .category-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% 50%;
  }
  .hero-overlay {
    position: absolute;
    z-index: 9;
    color: rgb(221, 211, 211);
    margin-top: 7rem;
    margin-left: 1%;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
}

/* desktop */
@media (min-width: 768px) {
  .hero-img {
    max-height: 22rem;
  }
  .home .collage {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .about-section {
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 15%;
    padding-right: 15%;
    background-color: var(--color-theme-light);
    margin-bottom: 3%;
    border-radius: 0.3rem;
  }
  .about-section p {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 1%;
    padding-right: 1%;
  }

  .about-img {
    width: auto;
    max-height: 10rem;
    border-radius: 100%;
    object-fit: fill;
    object-position: 10% 50%;
  }
  .about-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .category-section {
    padding-top: 1%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .category-section .column-center {
    margin-left: 1.5%;
    margin-right: 1.5%;
    cursor: pointer;
  }
  .category-section .category-wrapper {
    overflow: hidden;
    border-radius: 100%;
    width: 20rem;
    height: 20rem;
  }
  .category-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero-overlay {
    position: absolute;
    z-index: 9;
    color: rgb(221, 211, 211);
    margin-top: 19rem;
    margin-left: 1%;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
}
