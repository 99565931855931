.contact-form input[type="text"],
.contact-form textarea {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.contact-form .recap,
.contact-form .submit {
  justify-content: center;
}

.contact-form input[type="submit"] {
  width: 40%;
}

.contact-form .row {
  margin-bottom: 0.7rem;
}
.contact-form textarea {
  height: 8.4rem;
  font-family: inherit;
}
