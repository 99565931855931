/* mobile */
@media (max-width: 767px) {
  .receipt {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .receipt {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 15%;
    padding-right: 15%;
  }
}
