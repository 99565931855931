.nav {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
  color: var(--color-black);
  font-size: large;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* mobile */
@media (max-width: 767px) {
  .nav {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .mobilenavbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    padding-left: 2%;
    height: 100%;
    padding-right: 20%;
    background-color: var(--color-theme-light);
  }
  .navbar {
    display: none;
  }
  x {
    padding-top: 8%;
    padding-bottom: 8%;
    color: var(--color-black);
    cursor: pointer;
    font-size: x-large;
  }
  .shop {
    margin-top: 5%;
  }
  .shop {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 17%;
  }
  .shop .bag img {
    margin-bottom: 10%;
    max-height: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 971px) {
  .navbar {
    padding-right: 0.1%;
    padding-left: 0.1%;
  }
}
@media (min-width: 972px) and (max-width: 1171px) {
  .navbar {
    padding-right: 0.5%;
    padding-left: 0.5%;
  }
}
@media (min-width: 972px) and (max-width: 1186px) {
  .navbar {
    padding-right: 0.5%;
    padding-left: 0.5%;
  }
}
@media (min-width: 1187px) {
  .navbar {
    padding-right: 1%;
    padding-left: 1%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--color-theme-light);
  }
  .mobilenavbar {
    display: none;
  }
}
