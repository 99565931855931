.cart .product {
  width: unset !important;
}
.cart {
  padding-bottom: 10%;
}

/* mobile */
@media (max-width: 767px) {
  .cart .left {
    margin-left: 1%;
    width: 48%;
  }
  .hr {
    width: 150%;
  }
  .cart .right {
    position: absolute;
    width: 25%;
    right: 25%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .cart .left {
    margin-left: 15%;
    width: 35%;
  }
  .hr {
    width: 150%;
  }
  .cart .right {
    position: absolute;
    width: 18%;
    right: 25%;
  }
}
