.not-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.not-found h2 {
  padding-top: 10%;
  padding-bottom: 10%;
}

/* mobile */
@media (max-width: 767px) {
}

/* desktop */
@media (min-width: 768px) {
}
