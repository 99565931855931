.contact hr {
  margin-left: 20%;
  margin-right: 20%;
}
.contact .form-section {
  border-radius: 1rem;
  background-color: var(--color-white-faded);
}
/* mobile */
@media (max-width: 767px) {
  .contact title {
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
  }
  .contact section {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
  }
  .contact .form-section {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 5%;
    padding-left: 5%;
  }
  .contact .subtitle {
    display: flex;
    flex-direction: row;
    margin-left: 7%;
    margin-right: 7%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .contact title {
    display: flex;
    flex-direction: row;
    padding-left: 20%;
    padding-right: 20%;
  }
  .contact .img-section {
    padding-top: 5%;
  }
  .contact section {
    display: flex;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 100%;
  }
  .contact .form-section {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 10%;
    padding-left: 10%;
  }
  .contact .subtitle {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;
  }
}
