header {
  margin-top: 1%;
}
header .logo {
  height: auto;
  width: 100%;
} /* mobile */
@media (max-width: 767px) {
  header {
    position: sticky;
    top: 0;
    z-index: 90;
    background-color: var(--color-theme-main);
    padding-bottom: 1%;
  }

  .mobile-nav-icon {
    float: left;
    padding: 1%;
    z-index: 100;
  }
  .mobile-nav-icon div {
    width: 30px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.753);
    margin-bottom: 6px;
  }
  header .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  header .shop {
    display: none;
  }
}

/* desktop */
@media (min-width: 768px) {
  header .logo {
    max-height: auto;
    width: 40%;
    margin-left: 30%;
  }
  header .main {
    display: flex;
    flex-direction: row;
  }
  header .shop {
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0.3%;
  }
  header .shop .bag {
    margin-left: 20%;
  }
  header .shop .bag img {
    max-height: 3rem;
  }
}
