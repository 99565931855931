.product {
  width: 50%;
}

.edit {
  justify-content: center;
}

.edit span {
  width: 35%;
}

.edit textarea {
  margin-left: 5.3%;
  margin-right: 5.3%;
}

.product .modal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.product .modal-content {
  background-color: whitesmoke;
  margin: 3% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* mobile */
@media (max-width: 767px) {
  .modal-container {
    display: flex;
    flex-direction: column;
  }
  .modal-section {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .modal-section-secondary {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .modal-container {
    display: flex;
    flex-direction: row;
  }
  .modal-section,
  .modal-section-secondary {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 1rem;
  }
}
