/* mobile */
@media (max-width: 767px) {
  .about-section {
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 15%;
    padding-right: 15%;
    background-color: var(--color-theme-light);
    margin-bottom: 3%;
    border-radius: 0.3rem;
  }
  .about-section p {
    text-align: center;
  }
  .about-section .about-img {
    width: auto;
    max-height: 10rem;
    border-radius: 100%;
    object-fit: fill;
    object-position: 10% 50%;
    margin-bottom: 3%;
  }
  .about-section .about-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* desktop */
@media (min-width: 768px) {
  .about-section {
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 15%;
    padding-right: 15%;
    background-color: var(--color-theme-light);
    margin-bottom: 3%;
    border-radius: 0.3rem;
  }
  .about-section p {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 1%;
    padding-right: 1%;
  }

  .about-section .about-img {
    width: auto;
    max-height: 10rem;
    border-radius: 100%;
    object-fit: fill;
    object-position: 10% 50%;
  }
  .about-section .about-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
