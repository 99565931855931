.review {
  animation: fadein 2s;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
