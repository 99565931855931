.pagination-nav {
  background-color: var(--color-theme-light);
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 0.5%;
}

.pagination-selected {
  background-color: var(--color-theme-light-faded) !important;
}

/* mobile */
@media (max-width: 767px) {
  .products {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
    flex-wrap: wrap;
  }
  .products-page select {
    width: 50%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .products {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 15%;
    padding-right: 15%;
    flex-wrap: wrap;
  }
  .products-page select {
    width: 15%;
  }
}
