.newsletter {
  background-color: var(--color-theme-light);
  color: var(--color-black) !important;
}

/* mobile */
@media (max-width: 767px) {
  .newsletter {
    padding-left: var(--mobile-padding-x);
    padding-right: var(--mobile-padding-x);
    padding-top: var(--mobile-padding-y);
    padding-bottom: var(--mobile-padding-y);
    margin-bottom: var(--mobile-margin-y);
  }
  .newsletter input[type="button"] {
    width: 25%;
    margin-left: 5%;
  }

  .newsletter input {
    width: 70%;
  }
}

/* desktop */
@media (min-width: 768px) {
  .newsletter {
    padding-left: var(--desktop-padding-x);
    padding-right: var(--desktop-padding-x);
    padding-top: var(--desktop-padding-y);
    padding-bottom: var(--desktop-padding-y);
    margin-bottom: var(--desktop-margin-y);
  }
  .newsletter input[type="button"] {
    width: 15%;
    margin-left: 5%;
  }
  .newsletter input {
    width: 80%;
  }
}
