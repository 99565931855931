footer a {
  padding: 0%;
  color: var(--color-black) !important;
  border-radius: 0.25rem;
  max-height: 50px;
}
footer img {
  margin-right: 2px;
  height: 1rem;
  max-width: 1rem;
}
footer hr {
  padding: 0%;
}
footer {
  background-color: var(--color-theme-light);
}
.legal {
  background-color: var(--color-theme-light);
}

footer section {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}
/* mobile */
@media (max-width: 767px) {
  footer a {
    font-size: x-small;
    margin-left: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  footer {
    padding-left: var(--mobile-padding-x);
    padding-right: var(--mobile-padding-x);
    padding-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .legal {
    font-size: xx-small;
    display: flex;
    flex-direction: row;
    padding-bottom: 1%;
    justify-content: center;
    align-items: center;
  }
}

/* desktop */
@media (min-width: 768px) {
  footer a {
    font-size: small;
    margin-left: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  footer {
    padding-left: var(--desktop-padding-x);
    padding-right: var(--desktop-padding-x);
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .legal {
    font-size: x-small;
    display: flex;
    flex-direction: row;
    padding-bottom: 1%;
    justify-content: center;
    align-items: center;
  }
}
