.back {
  display: none;
}

.hover-image:hover .front {
  display: none;
}

.hover-image:hover .back {
  display: block;
  animation: fadein 2.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
